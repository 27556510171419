<template>
  <div>
    <div class="mt-4" v-for="(elem, index) in dashboard" :key="index">
      <div class="card-title">
        <h1>{{ elem.title }}</h1>
      </div>
      <el-row class="card-item">
        <el-col
          :xs="12"
          :sm="12"
          :md="6"
          v-for="(item, key) in elem.items"
          :key="key"
        >
          <!-- 如果此 item 是沒有任何角色限制 -->
          <div class="item">
            <div v-show="!item.show" class="item-cover">
              <div class="cover">
                <span class="cover-icon">
                  <i class="fas fa-lock"></i>
                </span>
                <span>Coming soon</span>
              </div>
            </div>
            <el-card shadow="hover">
              <div class="card-body">
                <!-- 如果此 item 是 Line Login -->
                <div
                  v-if="item.name === 'greet.line_login'"
                  style="height:100%"
                >
                  <a :href="lineLoginAuthUrl" class="card-content">
                    <div style="font-size: 100px">
                      <i class="fab" :class="item.icon"></i>
                    </div>
                    <div>
                      <span>{{ $t(item.name) }}</span>
                    </div>
                  </a>
                </div>
                <!-- 如果此 item 不是 Line Login -->
                <div v-else style="height:100%">
                  <router-link
                    v-if="item.routerName"
                    class="card-content"
                    :to="{
                      name: item.routerName,
                      query: item.query ? item.query : {},
                      params: item.params ? item.params : null
                    }"
                  >
                    <div class="font-icon">
                      <i class="fas" :class="item.icon"></i>
                    </div>
                    <div>
                      <span>
                        {{ $t(item.name) }}
                      </span>
                    </div>
                  </router-link>
                  <a v-else class="card-content" :href="item.path">
                    <div class="font-icon">
                      <i class="fas" :class="item.icon"></i>
                    </div>
                    <div>
                      <span>
                        {{ $t(item.name) }}
                      </span>
                    </div>
                  </a>
                </div>
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
import lineLoginApis from "@/apis/lineLogin";
/*eslint-enable */

import _ from "lodash";

export default {
  metaInfo() {
    return {
      title: "Home - " + this.CompanyName
    };
  },

  components: {},

  mixins: [],

  props: [],
  data() {
    return {
      isBindLineLogin: true,
      lineLoginAuthUrl: null,
      dashboard: [
        {
          title: "Educator Portal",
          items: [
            {
              // My Classes
              name: "Home.myClasses",
              routerName: "EducatorClassesList",
              auths: ["Educators"],
              icon: "fa-copyright",
              show: true
            },
            {
              // My Classes
              name: "Home.TestsManagement.Test Results",
              routerName: "Test Results",
              auths: ["Educators"],
              icon: "fas fa-chart-bar",
              show: false
            }
            // {
            //   // Applications
            //   name: "Home.applications",
            //   routerName: "applications",
            //   auths: ["super-admin"],
            //   icon: "fa-marker"
            // },
            // {
            //   // Counseling
            //   name: "Home.studentsAttendances",
            //   routerName: "studentsAttendances",
            //   auths: ["super-admin", "teacher"],
            //   icon: "fa-user-check"
            // },
            // {
            //   // Report Status
            //   name: "Home.reportStatuses",
            //   routerName: "reportStatuses",
            //   auths: ["super-admin"],
            //   icon: "fa-file-alt"
            // },
            // {
            //   // Enrollment
            //   name: "admin.enrollment",
            //   routerName: "EnrollmentList",
            //   auths: ["super-admin"],
            //   icon: "fa-envelope-open-text"
            // },
            // {
            //   // Placement
            //   name: "admin.placement_management",
            //   routerName: "Arrange",
            //   auths: ["super-admin"],
            //   icon: "fa-calendar-day"
            // },
            // {
            //   // quick enroll
            //   name: "greet.quick_enroll",
            //   routerName: "QuickEnrollNew",
            //   auths: ["super-admin", "parent", "student"],
            //   icon: "fas fa-file-signature"
            // }
          ]
        },
        // {
        //   title: "Tests",
        //   items: [
        //     {
        //       // ACT
        //       name: "greet.ACT",
        //       routerName: "ACTList",
        //       auths: ["all"],
        //       icon: "fa-pencil-alt",
        //       show: true
        //     },
        //     {
        //       // SAT
        //       name: "greet.SAT",
        //       path: `https://sat.ivy-way.com?token=${this.token}`,
        //       auths: ["all", "demo-role"],
        //       icon: "fa-pencil-alt",
        //       show: false
        //     },
        //     {
        //       // AP
        //       name: "greet.AP",
        //       path: `https://sat.ivy-way.com?token=${this.token}`,
        //       auths: ["all", "demo-role"],
        //       icon: "fa-pencil-alt",
        //       show: false
        //     },
        //     {
        //       // IELTS
        //       name: "greet.IELTS",
        //       routerName: "ToeflList",
        //       auths: ["all", "demo-role"],
        //       icon: "fa-pencil-alt",
        //       show: false
        //     },
        //     {
        //       // TOEFL
        //       name: "greet.TOEFL",
        //       routerName: "ToeflList",
        //       auths: ["all", "demo-role"],
        //       icon: "fa-pencil-alt",
        //       show: false
        //     }
        //   ]
        // },
        {
          title: "Tests & Questions Management",
          items: [
            {
              name: "Home.TestsManagement.QuestionsBox",
              routerName: "EducatorQuestions",
              auths: ["Educators"],
              icon: "fas fa-clipboard-list",
              show: true
            },
            {
              name: "Home.TestsManagement.Full Tests",
              routerName: "EducatorFullTestsList",
              auths: ["Educators"],
              icon: "fas fa-chart-bar",
              show: true
            },
            {
              name: "Home.TestsManagement.Partial Tests",
              routerName: "EducatorModules",
              params: {
                type: "modules"
              },
              auths: ["Educators"],
              icon: "fas fa-chart-bar",
              show: true
            },
            {
              name: "Home.TestsManagement.Drill Practice",
              routerName: "EducatorModules",
              params: {
                type: "drills"
              },
              auths: ["Educators"],
              icon: "fas fa-chart-bar",
              show: true
            },
            {
              name: "Home.TestsManagement.Question Labels",
              routerName: "myClasses",
              auths: ["Educators"],
              icon: "fas fa-tags",
              show: false
            }
          ]
        },
        // {
        //   title: "Resources",
        //   items: [
        //     {
        //       // Flash Cards
        //       name: "Home.flashcards",
        //       routerName: "FlashCards",
        //       auths: ["all"],
        //       icon: "fa-book",
        //       show: true
        //     },
        //     {
        //       // SchoolsList
        //       name: "Home.colleges",
        //       routerName: "SchoolsList",
        //       query: { rank: 12 },
        //       auths: ["all"],
        //       icon: "fa-graduation-cap",
        //       show: false
        //     }
        //     // {
        //     //   name: "Home.collegeAliases",
        //     //   routerName: "collegeAliases",
        //     //   auths: ["super-admin", "administrative-assistant"],
        //     //   icon: "fa-university"
        //     // },
        //     // {
        //     //   name: "Home.attachLinkToSchool",
        //     //   routerName: "attachLinkToSchool",
        //     //   auths: ["super-admin", "administrative-assistant"],
        //     //   icon: "fa-university"
        //     // },
        //     // {
        //     //   name: "Home.postCreator",
        //     //   routerName: "postCreator",
        //     //   auths: ["super-admin", "administrative-assistant"],
        //     //   icon: "fa-university"
        //     // }
        //   ]
        // },
        {
          title: "Settings",
          items: [
            {
              // My Classes
              name: "Home.Profile",
              routerName: "EducatorProfile",
              auths: ["all"],
              icon: "fa-user",
              show: true
            }
          ]
        }
      ]
    };
  },
  computed: {
    ...mapState("user", ["profile", "token"]),
    isLogin() {
      // TODO: 應該還要檢查 token 是否合法？
      return this.token !== "";
    },
    sat() {
      return this.$route.query.sat;
    },
    role() {
      return this.getRole(this.$route.name);
    }
  },
  watch: {},

  async mounted() {
    // this.dashboard[1].items.push({
    //   // SAT
    //   name: "greet.SAT",
    //   path: `https://sat.ivy-way.com?token=${this.token}`,
    //   auths: ["all", "demo-role"],
    //   icon: "fa-pencil-alt"
    // });
  },

  methods: {
    getRole(routeName) {
      if (routeName.indexOf("students") > -1) {
        return "Students";
      } else if (routeName.indexOf("Educators") > -1) {
        return "Educators";
      } else {
        return "Students";
      }
    },
    hasPrimission({ items }) {
      return items.some(item => {
        return this.permissionAllow(item.auths);
      });
    },
    async fetchLineLoginAuthURL() {
      let url = await this.$router.resolve({
        name: "BindLineLogin"
      });
      let lineLoginAuthUrl = await lineLoginApis.getAuthURL({
        redirect_uri: window.location.origin + url.href
      });
      this.lineLoginAuthUrl = lineLoginAuthUrl.url;
    },
    permissionAllow(allowRoles) {
      // let role = this.profile.all_roles;
      let role = [this.role];
      if (_.includes(role, "demo-role")) {
        if (_.includes(allowRoles, "demo-role")) {
          if (_.includes(allowRoles, "all")) {
            return true;
          }

          return _.intersection(role, allowRoles).length > 1;
        }
        return false;
      }

      if (_.includes(allowRoles, "all")) {
        return true;
      }

      return _.intersection(role, allowRoles).length > 0;
    },
    ...mapActions("user", ["getProfile"])
  }
};
</script>

<style scoped>
.bread-header {
  margin: 0 50px;
}
.card-title {
  margin-left: 50px;
}
.card-item {
  margin-left: 25px;
  margin-right: 25px;
}
.font-icon {
  font-size: 100px;
}
.item {
  padding: 20px;
  position: relative;
}
.item-cover {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 20px;
}
.item-cover .cover {
  background-color: black;
  opacity: 0.8;
  width: 100%;
  height: 100%;
  color: white;
  display: flex;
  font-size: 1.5rem;
  flex-direction:column;
  align-items: center;
  justify-content: center;
}
.center-row {
  height: 20vh;
}

.greet-color {
  color: #42a16a;
}

.greet-center {
  text-align: center;
}

a {
  text-decoration: none;
  color: #909399;
}

.card-body {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0px;
  padding-bottom: 5px;
  height: 180px;
  text-align: center;
}

.card-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 1.5rem;
    margin-top: 5px;
  }
  .card-title {
    margin-left: 20px;
  }
  .card-item {
    margin: 0 10px;
  }
  .item,
  .item-cover {
    padding: 10px;
  }
  .font-icon {
    font-size: 70px;
  }
  .card-body {
    height: 160px;
  }
  .bread-header {
    margin: 0 20px;
  }
}
</style>
